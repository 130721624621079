import React from "react"
import Image from "../components/image"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from 'react-grid-system';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faArrowDown} from "@fortawesome/free-solid-svg-icons"


class Page extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
    <Layout container={false} isDark={true} hasDarkColorScheme={true}>
        <SEO title="Media for Future! #gogreen" />

            <div className="container-fluid hero-full hero-big" id="gogreen">
                <div className="text-center">
                    <h1 class="text-white">Media for Future</h1>
                    <p className="hidden-sm  text-white mt-2"> Bitte nach unten scrollen.</p>
                </div>
            </div>

            <div className="container-fluid hero-full" id="solar_power">
                <div className="text-center">
                    <h1 class="text-white">Wir sind stolz auf unsere Partnerschaft mit Webgo, die 100% Ökostrom verwenden.</h1>
<a target="_blank" href="https://wpp.webgo.de/trck/eclick/fb73d2cb4b9a8065e8033e68aefc12f2">
                            <picture>
                                <source srcSet={require("../images/landing/partner_webgo.jpg.webp")} type="image/webp"/>
                                <source srcSet={require("../images/landing/partner_webgo.jpg")} type="image/jpg"/>
                                <img style={{height:"60px"}} className="border" src={require("../images/landing/partner_webgo.jpg")}/>
                            </picture>
            </a>
            <br/>
            <br/>

                    <a class="text-white" href="https://www.webgo.de/downloadfile?input=webgo-Ökostrom-Zertifikat-RZ-HH.pdf">
          <FontAwesomeIcon icon={faArrowDown} />   Ökostrom Zertifikat </a>
            <br/>
            <br/>

                </div>
            </div>



            <div className="container-fluid hero container-col-2" id="train">
                    <div className="img"></div>
                    <div className="textbox">
                        <h1>Grüne Kundenbesuche</h1>
                        <p className="text-gray">
                Wir kommen per Bahn zu Ihnen, und das zu jeder Zeit und ohne Stau.</p>
                </div>
            </div>


      <div className="container-fluid hero container-col-2" id="invoice">
                    <div className="img"></div>
                    <div className="textbox">
                        <h1>Digital statt Papier</h1>
                        <p className="text-gray">Sie erhalten Angebote, Rechnungen und weitere Dokumente in digitaler Form, um Papier zu sparen.
</p>
                </div>
            </div>

      <a name="film" id="film"></a>
            <div className="container-fluid hero container-col-2" id="filmmakersforfuture">
                    <div className="img" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <picture>
                            <source srcSet={require("../images/gogreen/filmmakersforfuture.jpg.webp")} type="image/webp"/>
                            <source srcSet={require("../images/gogreen/filmmakersforfuture.jpg")} type="image/jpeg"/>
                            <img loading="lazy" style={{borderRadius:"50%", display:"block", margin:"auto",
                                    width:"60%"}} src={require("../images/gogreen/filmmakersforfuture.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Werbefilm</h1>
                        <p className="text-gray">Unser Ziel ist es, die Umweltauswirkungen der globalen Filmindustrie zu minimieren. Wir konzentrieren uns in erster Linie auf die Bewusstseinsbildung und die Förderung des Wissens- und Erfahrungsaustauschs.</p>
                        <p className="text-gray text-small">* Details unter filmmakersforfuture.org</p>
                        <Link className="text-lime" to="/services/imagefilm">Mehr erfahren ›</Link>
                </div>
            </div>

            <div className="container-fluid hero container-col-2" id="catering">
                    <div className="img"></div>
                    <div className="textbox">
                        <h1>Catering & Geschäftsessen</h1>
                        <p className="text-gray">Ein gutes Arbeitsklima braucht gutes Essen. Darum gibt es bei uns saisonale und regionale Verpflegung.  </p>
                </div>
            </div>

            <div className="container-fluid hero" id="coffee">
                <div className="container">

                        <picture>
                            <source srcSet={require("../images/landing/coffee_1500.jpg.webp")} type="image/webp"/>
                            <source srcSet={require("../images/landing/coffee_1500.jpg")} type="image/jpeg"/>
                            <img loading="lazy" src={require("../images/landing/coffee_1500.jpg")}/>
                        </picture>


                    <div className="textbox">
                        <h1 className="text-white">Auf n Kaffee?</h1>
                        <p className="text-white">Gerne erzählen wir Ihnen mehr!  </p>
                        <Link to="/mehr-erfahren" className="text-white btn btn-lime-outline">Jetzt anfragen ›</Link>
                    </div>
                </div>
            </div>



           </Layout>)
    }
}

export default Page
